import React from "react";

export const phoneNumberFormatter = (cell, row) => {
  
  if (!cell) {
    return ''; 
  }

  const maskedNumber = cell?.replace(/(\d{2})(\d{6})(\d{2})/, "$1XXXXXX$3");
  return maskedNumber;
};
