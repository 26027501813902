import axios from "axios";
import { PortfolioUrls } from "./constantApiUrls";

const { REACT_APP_JAVA_API_URL, REACT_APP_API_URL } = process.env;

const getProduct = async (tenantId) => {
  try {
    return await fetch(
      `${REACT_APP_JAVA_API_URL}${PortfolioUrls.getProductUrl}`,
      {
        headers: {
          tenantId,
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      return res.json();
    });
  } catch (error) {
    if (error.response)
      console.log("error in get Product api ", error.response);
  }
};

const getReportByTenantId = async (tenantId, sub) => {
  try {
    return await axios.get(
      `${REACT_APP_JAVA_API_URL}${PortfolioUrls.getReportByTenantId}`,
      {
        headers: {
          tenantId,
          sub,
        },
      }
    );
  } catch (error) {
    if (error.response) {
      console.log("error in get product by id ", error.reponse);
    }
  }
};

const createPortfolio = async (data, tenantId, sub) => {
  try {
    return await axios.post(
      `${REACT_APP_JAVA_API_URL}${PortfolioUrls.createPortfolioUrl}`,
      data,
      {
        headers: {
          tenantId,
          sub,
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error) {
    if (error.response) {
      console.log("error in create tenant api ", error.reponse);
    }
  }
};

const getPortfolioById = async (portfolioId, sub, tenantId) => {
  try {
    return await axios.get(
      `${REACT_APP_JAVA_API_URL}${PortfolioUrls.getPortfolioById}${portfolioId}`,
      {
        headers: {
          tenantId,
          sub,
        },
      }
    );
  } catch (error) {
    if (error.response) {
      console.log("error in get portfolio by id ", error.response);
    }
  }
};

const activeInactivePortfolio = async (portfolioId, sub, tenantId, active) => {
  try {
    return await axios.put(
      `${REACT_APP_JAVA_API_URL}${PortfolioUrls.active_inactive_url}`,
      {},
      {
        headers: {
          portfolioId,
          sub,
          tenantId,
          active,
        },
      }
    );
  } catch (error) {
    if (error.response)
      console.log("error in active inactive api ", error.response);
  }
};

const updatePortFolio = async (tenantId, sub, data, portfolioId) => {
  try {
    return await axios.put(
      `${REACT_APP_JAVA_API_URL}${PortfolioUrls.getPortfolioById}${portfolioId}`,
      data,
      {
        headers: {
          tenantId,
          sub,
        },
      }
    );
  } catch (error) {
    if (error.response) {
      console.log("error in update portfolio  ", error.response);
    }
  }
};

const updateScriptPortfolio = async (portfolioId, sub, data) => {
  try {
    return await axios.put(
      `${REACT_APP_JAVA_API_URL}${PortfolioUrls.updatePortfolioScriptUrl}`,
      data,
      {
        headers: {
          sub,
          portfolioId,
        },
      }
    );
  } catch (error) {
    if (error.response)
      console.log("error in update script portfolio ", error.response);
  }
};

const updateForclosureAndSettlement = async (portfolioId, sub, data) => {
  try {
    return await axios.put(
      `${REACT_APP_JAVA_API_URL}${PortfolioUrls.updatePortfolioForclosureUrl}`,
      data,
      {
        headers: {
          sub,
          portfolioId,
        },
      }
    );
  } catch (error) {
    if (error.response)
      console.log("error in update forclosure and settlement ", error.response);
  }
};

const updateIncentive = async (portfolioId, sub, data) => {
  try {
    return await axios.put(
      `${REACT_APP_JAVA_API_URL}${PortfolioUrls.updateIncentiveUrl}`,
      data,
      {
        headers: {
          sub,
          portfolioId,
        },
      }
    );
  } catch (error) {
    if (error.response)
      console.log("error in update incentive ", error.response);
  }
};

const getReportInSettlement = async (sub, portfolioId) => {
  try {
    return await axios.get(
      `${REACT_APP_JAVA_API_URL}${PortfolioUrls.getReportInSettlement}`,
      {
        headers: {
          sub,
          portfolioId,
        },
      }
    );
  } catch (error) {
    if (error.response)
      console.log("error in get upload report in settlement ", error.response);
  }
};

const upploadReportInSettlement = async (portfolioId, file) => {
  const formData = new FormData();
  formData.append("file", file);
  try {
    return await axios.post(
      `${REACT_APP_JAVA_API_URL}${PortfolioUrls.uploadReportInSettlement}`,
      formData,
      {
        headers: {
          portfolioId,
        },
      }
    );
  } catch (error) {
    if (error.response)
      console.log("error in get upload report in settlement ", error.response);
  }
};

const CreatSettlementForclosureLetter = async (portfolioId, tenantId, data) => {
  try {
    return await axios.post(
      `${REACT_APP_JAVA_API_URL}${PortfolioUrls.settlementForclosureLetterUrl}`,
      data,
      {
        headers: {
          portfolioId,
          tenantId,
        },
      }
    );
  } catch (error) {
    if (error.response)
      console.log("error in get upload report in settlement ", error.response);
  }
};

const uplodFileOnS3 = async (file) => {
  try {
    return await axios.post(
      `${REACT_APP_API_URL}${PortfolioUrls.uploadFileOnS3Url}`,
      file
    );
  } catch (error) {
    if (error.response)
      console.log("error in uplod on s3 bucket ", error.response);
  }
};

const editSrAndFrLetter = async (
  data,
  tenantId,
  portfolioId,
  SRAndFRLettersId
) => {
  try {
    return await axios.put(
      `${REACT_APP_JAVA_API_URL}${PortfolioUrls.editSrAndFrLetterUrl}`,
      data,
      {
        headers: {
          tenantId,
          portfolioId,
          SRAndFRLettersId,
        },
      }
    );
  } catch (error) {
    if (error.response)
      console.log("Error in edit sr and fr letter", error.response);
  }
};

const getAllSRAndFR = async (tenantId, portfolioId) => {
  try {
    return await axios.get(
      `${REACT_APP_JAVA_API_URL}${PortfolioUrls.SRandFrListingUrl}`,
      {
        headers: {
          tenantId,
          portfolioId,
        },
      }
    );
  } catch (error) {
    if (error.response)
      console.log("error in get all sr and fr api ", error.response);
  }
};

const getSRandFRLetter = async (tenantId, portfolioId, letterType) => {
  try {
    return await axios.get(
      `${REACT_APP_JAVA_API_URL}${PortfolioUrls.GET_SR_FR_BY_LETTERTYPE}`,
      {
        headers: {
          tenantId,
          portfolioId,
          letterType,
        },
      }
    );
  } catch (error) {
    if (error.response)
      console.log("error in sr and fr letter api ", error.response);
  }
};

const deleteLetter = async (SRAndFRLettersId, active) => {
  try {
    return await axios.put(
      `${REACT_APP_JAVA_API_URL}${PortfolioUrls.deleteLetterUrl}`,
      {},
      {
        headers: {
          SRAndFRLettersId,
          active,
        },
      }
    );
  } catch (error) {
    if (error.response)
      console.log("error in  deleting the letter", error.response);
  }
};

const validatePortfolio = async (bucketId, productId, portfolioName, sub) => {
  try {
    return await axios.get(
      `${REACT_APP_JAVA_API_URL}${PortfolioUrls.validatePortfolioUrl}`,
      {
        headers: {
          bucketId,
          productId,
          portfolioName,
          sub,
        },
      }
    );
  } catch (error) {
    if (error.response) {
      console.log("Error in validate portfolio:", error.response);
      throw {
        status: error.response.status,
        message: error.response.data.message || "Validation failed.",
      };
    } else {
      throw {
        status: 500,
        message: "An unexpected error occurred during validation.",
      };
    }
  }
};

const PortfolioApi = {
  getProduct,
  getReportByTenantId,
  createPortfolio,
  getPortfolioById,
  activeInactivePortfolio,
  updatePortFolio,
  updateScriptPortfolio,
  updateForclosureAndSettlement,
  updateIncentive,
  upploadReportInSettlement,
  getReportInSettlement,
  CreatSettlementForclosureLetter,
  uplodFileOnS3,
  editSrAndFrLetter,
  getAllSRAndFR,
  getSRandFRLetter,
  deleteLetter,
  validatePortfolio,
};

export default PortfolioApi;
