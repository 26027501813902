import { tenantConstants } from "../constants/ActionTypes";

const initialState = {
  tenants: [],
};

const tenant = (state = initialState, { type, payload }) => {
  switch (type) {
    case tenantConstants.GET_TENANTS_ALL:
      return { ...state, tenants: payload };

    case tenantConstants.SET_TENANT:
      return { ...state, tenantId: payload };

    case tenantConstants.UPDATED_TENANT:
      return { ...state, tenants: payload };

    default:
      return state;
  }
};

export default tenant;
