import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import $ from "jquery";
import "./style.min.scss";
import React from "react";
import ReactDOM from "react-dom";
import "mdbootstrap/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import App from "./App";
// import * as serviceWorker from './serviceWorker';
import reportWebVitals from "./reportWebVitals";
window.jQuery = $;
window.$ = $;
global.jQuery = $;
ReactDOM.render(
  //   <React.StrictMode>
  <React.Fragment>
    <App />
    {/* </React.StrictMode>, */}
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
