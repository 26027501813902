import { customerConstants, API_URL } from "../constants/ActionTypes";
import axios from "axios";
import history from "../history";
// const API_URL = 'http://localhost:3001/api/';
const defaultOptions = {
  headers: { Authorization: "Bearer " + localStorage.getItem("token") },
};

export const getCustomers = () => (dispatch) => {
  return fetch(API_URL + "/customers")
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      // console.log("customers actions ", result);
      dispatch({
        type: customerConstants.GET_CUSTOMERS,
        payload: result.customers,
      });
    });
};

export const addCustomer = (customer) => {
  // console.log("customer ", customer);
  return (dispatch) => {
    return axios
      .post(API_URL + "/customers/", customer, defaultOptions)
      .then((res) => {
        // console.log("response ", res);
        dispatch({
          type: customerConstants.ADD_CUSTOMER,
          payload: res.data.result,
        });
        history.push(`/customers`);
      });
  };
};

export const updateCustomer = (customer) => {
  // console.log("udpate customer customer customer ", customer);
  return (dispatch) => {
    return axios
      .put(API_URL + "/customers/", customer, defaultOptions)
      .then((res) => {
        // console.log("response ", res);

        history.push(`/customers`);
      });
  };
};

export const deleteCustomer = (customer) => {
  return (dispatch) => {
    return axios
      .delete(API_URL + "/customers", { data: customer }, defaultOptions)
      .then((res) => {
        dispatch({
          type: customerConstants.DELETE_CUSTOMER,
          payload: customer,
        });
      });
  };
};
