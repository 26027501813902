import "./MinimizeDialogComponent.css";
import React, { useState } from "react";

import { getLoginSub, getProfileDetail } from "../Login/authUtils";

const { username } = getProfileDetail();

const URL = `https://r130.deepijatel.in/ConVoxCCS/convoxphonframe.php?agent_id=${username}`;
const MinimizeDialogComponent = () => {
  const [isMinimized, setIsMinimized] = useState(true);

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const URL = `https://r130.deepijatel.in/ConVoxCCS/convoxphonframe.php?agent_id=${username}`;

  return (
    <div
      className={`iframe-container ${isMinimized ? "minimized" : "maximized"}`}
    >
      <div className="header" onClick={handleMinimize}>
        <div className="title">WEBRTC</div>
        <div className="controls">
          <span>{isMinimized ? "□" : "_"}</span>
        </div>
      </div>
      <iframe
        src={URL}
        title="Persistent Iframe"
        allow="camera; microphone; display-capture; autoplay; clipboard-write;"
      ></iframe>
    </div>
  );
};

export default MinimizeDialogComponent;
