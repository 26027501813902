import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Redirect, useLocation } from "react-router-dom";
import { logout } from "./actions";
// import Footer from "./components/common/Footer";
// import Header from "./components/common/Header";
import Sidemenu from "./components/common/Sidemenu";
import LandingSidemenu from "./components/common/LandingSidemenu";
import AltHeader from "./components/common/AltHeader";
// import AltSidemenu from "./components/common/AltSideMenu";
import IdleTimer from "./IdleTimer";
import "./components/AssignedLoan/activeLoans.scss";
import AlertTable from "./components/Notifications/Table";
import "./components/common/common.scss";
import Axios from "axios";
import { useSelector } from "react-redux";
import { handleModal } from "./actions/collectorAction";
import history from "./history";
import SpinnerCont from "./constituents/Spinner/SpinnerCont";

let user = JSON.parse(localStorage.getItem("collektoUser"));
if (user?.role !== "COLLECTOR") {
  user = {};
}
const { REACT_APP_JAVA_API_URL } = process.env;

// const rowEvents = {
//   onClick: (e, row, rowIndex) => {
//     // console.log("row is:", row);
//     if (row.activity == "MANAGER_APPROVED") {
//       this.props.history.push(`/approveLoanByClient`, {
//         loanId: row._id,
//         Loan_ID: row.Loan_ID,
//         _id: row._id,
//       });
//     }

//     let col_index = null;
//     if (e.target.tagName == "A") {
//       let col_child = e.target.parentNode;
//       let col_parent = col_child.parentNode;
//       col_index = Array.prototype.indexOf.call(col_parent.children, col_child);
//     } else {
//       let col_child = e.target;
//       let col_parent = col_child.parentNode;
//       col_index = Array.prototype.indexOf.call(col_parent.children, col_child);
//     }

//     // 10 index for remarks column
//     if (col_index !== 10) {
//       if (this.role == "MANAGER") {
//         if (row.activity == "FR_created" || row.activity == "SR_created") {
//           this.props.history.push(`/approveLoan`, row);
//         }
//       } else if (this.role == "CUSTOMER") {
//         if (row.activity == "LETTER_UPLOADING") {
//           // console.log(`clicked on row with index: ${rowIndex}`);
//           // this.props.dispatch({ type: callManagementConstants.SEARCH_HISTORY, payload: this.state })
//           this.props.history.push(`/letterUploadByClient`, row);
//         }
//       }
//     }
//   },
// };

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const alertsRowEvent = {
    onClick: async (e, row, rowIndex) => {
      //
      // console.log("current row is", row);
      const axiosReq = Axios.create({
        headers: {
          collectorId: user.id,
        },
      });
      // console.log("expected row is: ", row);

      await axiosReq.put(
        `${REACT_APP_JAVA_API_URL}/api/v2/alerts/new/${row.id}/deactivate`
      );
      history.push(`/call-management`, {
        _id: row.loanId,
        Loan_ID: row.loanNumber,
      });
      // this.handleCloseModal(false)
      dispatch(handleModal(false));
      // window.location.reload();
    },
  };
  let currentUser = JSON.parse(localStorage.getItem("collektoUser"));

  // console.log(`currentUser `, currentUser);
  const location = useLocation();
  const state = useSelector((state) => state);
  let missedAlerts = state.collectors?.missedAlerts?.data;
  let ShowModal = state.collectors?.showAlertsModal;
  // // console.log('state is', state.collectors?.missedAlerts?.data);
  let isHeader = <AltHeader />;
  /* localStorage.getItem('collektoUser')?
    (location.pathname==="/call-management" ||location.pathname==="/" || location.pathname==="/redirectBi" || location.pathname==="/predictive-call" || location.pathname==="/active-loans"|| location.pathname==="/agentProfiling" || location.pathname==="/home" || location.pathname ==="/notifications")? 
    <AltHeader/> */
  // : <Header />
  //(location.pathname==="/redirectBi")?null:<Header/>
  // : null
  let isSidemenu = localStorage.getItem("collektoUser") ? (
    location.pathname === "/home" ? (
      <LandingSidemenu />
    ) : (
      <Sidemenu />
    )
  ) : (
    <Sidemenu />
  );
  // let isFooter = localStorage.getItem('collektoUser')?(location.pathname==="/call-management" || location.pathname==="/predictive-call" || location.pathname==="/active-loans"|| location.pathname==="/agentProfiling"  || location.pathname ==="/notifications")? null : <Sidemenu/> : null;
  //  <Footer /> : null;

  const [isTimeout, setIsTimeout] = useState(false);
  useEffect(() => {
    // console.log(`each click 1`);

    const timer = new IdleTimer({
      timeout: 600, //expire after 600 seconds
      onTimeout: () => {
        setIsTimeout(true);
      },
      onExpired: () => {
        //do something if expired on load
        setIsTimeout(true);
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  const dispatch = useDispatch();
  const sampleAlert = missedAlerts?.length ? missedAlerts[0] : {};
  const alertCols = [];

  for (let k in sampleAlert) {
    let l = k;
    if (k === "id" || k == "loanId") continue;
    if (k === "loanId") {
      l = "loanNumber";
    }
    const text = l
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());

    const col = {
      dataField: k,
      text: text,
    };
    if (l === "createdAt" || l === "updatedAt" || l === "alertDateTime") {
      col.formatter = (cell) => {
        return cell.split("T")[0];
      };
    }

    alertCols.push(col);
  }
  function handleCloseModal(value) {
    dispatch(handleModal(value));
  }

  return (
    <div>
      {isHeader}
      {isSidemenu}
      <SpinnerCont />
      <div
        className={`modal ${ShowModal ? "show" : ""} text-center`}
        style={{ display: ShowModal ? "block" : "none", paddingRight: "8px" }}
        //   style={{'display': ((totalCounts > 0)?'block':'none')}}
        aria-modal="true"
        id={`_tet`}
      >
        <div
          style={{ width: "90%", maxWidth: "90%" }}
          className="modal-dialog modal-dialog-header modal-lg mx-auto"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Alert</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => handleCloseModal(false)}
              >
                ×
              </button>
            </div>
            <div className="modal-body">
              <AlertTable
                missedAlerts={missedAlerts}
                alertCols={alertCols}
                rowEvents={alertsRowEvent}
              />
            </div>
          </div>
        </div>
      </div>
      <Route
        {...rest}
        render={(props) =>
          localStorage.getItem("collektoUser") ? (
            <Fragment>
              {roles &&
                roles.indexOf(currentUser.role?.name || currentUser?.role) ===
                  -1 && <Redirect to={{ pathname: "/" }} />}

              {isTimeout && dispatch(logout())}

              <Component {...props} />
            </Fragment>
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }
      />

      {/* {isFooter} */}
    </div>
  );
};

// const mapDispatchToProps = dispatch => ({
//     logout : () => dispatch(logout())
// })

// export default connect(null, mapDispatchToProps)(PrivateRoute)
