import { combineReducers } from "redux";
import authentication from "./authenticationReducer";
import loans from "./loanReducer";
import buckets from "./bucketReducer";
import employees from "./employeeReducer";
import collectors from "./collectorReducer";
import managers from "./managerReducer";
import clients from "./clientReducer";
import portfolios from "./portfolioReducer";
import callmanagement from "./callManagementReducer";
import partners from "./partnerReducer";
import partnerloans from "./partnerloanReducer";
import dataVisualizations from "./dataVisualizationReducer";
import userReducer from "./userReducer";
import target from "./targetReducer";
import analytical from "./analyticalReducer";
import datainsights from "./dataInsightReducer";
import recoverypredictor from "./recoveryPredictorReducer";
import fieldagents from "./fieldAgentReducer";
import backups from "./backupReducer";
import contact from "./contactReducer";
import revenueReducer from "./revenueReducer";
import tenantDetails from "./tenantReducer";
import registrationDetail from "./registrationReducer";
import products from "./productReducer";
import util from "./utilReducer";
import notificationSavingData from "./notificationReducer";
const rootReducer = combineReducers({
  authentication,
  loans,
  buckets,
  employees,
  collectors,
  managers,
  clients,
  portfolios,
  callmanagement,
  partners,
  partnerloans,
  dataVisualizations,
  userReducer,
  target,
  analytical,
  datainsights,
  recoverypredictor,
  fieldagents,
  backups,
  contact,
  revenueReducer,
  tenantDetails,
  registrationDetail,
  products,
  util,
  notificationSavingData,
});

export default rootReducer;
