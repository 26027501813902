import { portfolioConstants } from "../constants/ActionTypes";

const initialState = {
  portfolios: [],
  tenantPortfolios: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case portfolioConstants.GET_PORTFOLIO_ALL:
      return { ...state, portfolios: payload };

    case portfolioConstants.GET_PORTFOLIO_BY_TENANT:
      return { ...state, tenantPortfolios: payload };
    case portfolioConstants.REST_TENANT_PORTFOLIO:
      return { ...state, tenantPortfolios: [] };
    default:
      return state;
  }
};
