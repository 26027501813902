import { utilConstants } from "../constants/ActionTypes";

const initialState = {
  activeTab: "",
};

const util = (state = initialState, { type, payload }) => {
  switch (type) {
    case utilConstants.SET_ACTIVE_TAB:
      return { ...state, activeTab: payload };
    default:
      return state;
  }
};

export default util;
