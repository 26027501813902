import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { logout } from "../../actions/authenticationAction";
import { connect } from "react-redux";
import { getMissedAlerts } from "../../actions/collectorAction";
import apiAxios from "../../api";
import "./common.scss";
const { REACT_APP_JAVA_API_URL } = process.env;

// import logoSymbol from 'assets/images/logo-symbol.png'
class Header extends Component {
  constructor(props) {
    super(props);
    const { collektoUser } = this.props.authentication;
    //  // console.log(`header this.props.authentication`, this.props.authentication)
    document.body.classList.add("mini-sidebar");
    if (collektoUser && collektoUser.role === "CUSTOMER") {
      // document.body.classList.add('single-column');
      // document.body.classList.remove('mini-sidebar');
    }
    if (collektoUser.role == "COLLECTOR") {
      this.props.getMissedAlerts(collektoUser.id);
    }

    //  this.handleMenuClick = this.handleMenuClick.bind(this)
    this.state = {
      showMiniSideBar: true,
    };
  }
  static propTypes = {
    authentication: PropTypes.object.isRequired,
  };
  // componentDidMount(){
  //     document.body.classList.add('mini-sidebar');
  //     this.setState({showMiniSideBar: true})
  // }

  handleMenuClick = () => {
    // if (document.body.classList.contains('mini-sidebar')) {
    if (this.state.showMiniSideBar) {
      document.body.classList.remove("mini-sidebar");
      this.setState({ showMiniSideBar: false });
    } else {
      document.body.classList.add("mini-sidebar");
      this.setState({ showMiniSideBar: true });
    }
    // if(this.state.showMiniSideBar){
    //     document.body.classList.remove('mini-sidebar');
    //     this.setState({ showMiniSideBar: false });
    // }else{
    //     document.body.classList.add('mini-sidebar');

    // this.setState({ showMiniSideBar: true });
    // }
  };

  logout = () => {
    const { collektoUser } = this.props.authentication;
    // apiAxios.post(`${REACT_APP_JAVA_API_URL}/api/v2/dialer/logout`, {
    //   username: collektoUser.username,
    // });
    this.props.logout();
  };
  render() {
    const { collektoUser } = this.props.authentication
      ? this.props.authentication
      : {};
    const { role } = collektoUser ? collektoUser : {};
    let { alertsCount } = this.props.collectors;
    if (!alertsCount) {
      alertsCount = 0;
    }
    // console.log("alertsCount: ", alertsCount);
    return (
      <header className="topbar">
        <nav className="navbar top-navbar navbar-expand-md navbar-dark">
          {/* ============================================================== */}
          {/* Logo */}
          {/* ============================================================== */}
          <div className="navbar-header">
            <Link className="navbar-brand" to="/">
              <b className="brand">
                {/* <img src="/assets/images/logo-symbol.png" alt="homepage" className="dark-logo" /> */}

                <img
                  src="/assets/images/logo-symbol.png"
                  alt="homepage"
                  className="light-logo"
                />
              </b>
              <span>
                {/* { !(this.state.showMiniSideBar) && */}
                {/* <React.Fragment> */}
                {/* <div id="logo"  className="dark-logo"></div> */}
                <div
                  id={`${this.state.showMiniSideBar ? "" : "logo"}`}
                  className="light-logo"
                ></div>
                {/* </React.Fragment> */}
                {/* } */}
              </span>
            </Link>
          </div>
          {/* ============================================================== */}
          {/* End Logo */}
          {/* ============================================================== */}
          <div className="navbar-collapse">
            <ul className="navbar-nav mr-auto">
              {/* This is  */}
              <li className="nav-item">
                {" "}
                <a
                  className="nav-link silder_hide nav-toggler d-block d-sm-none waves-effect waves-dark"
                  dangerouslysethref="javascript:void(0);"
                >
                  <i className="ti-menu" />
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a
                  className="nav-link sidebartoggler d-lg-block d-md-block waves-effect waves-dark"
                  onClick={this.handleMenuClick}
                  dangerouslysethref="javascript:void(0);"
                >
                  <i className="icon-menu" />
                </a>{" "}
              </li>
            </ul>
            <ul className="navbar-nav my-lg-0">
              <li className="nav-item dropdown u-pro">
                <a
                  className="nav-link dropdown-toggle waves-effect waves-dark profile-pic"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {collektoUser?.fullName}
                  <span className="hidden-md-down">
                    <img
                      src={`/assets/images/user.png`}
                      alt="homepage"
                      className="clientLogo"
                    />
                    {role === "COLLECTOR" && (
                      <span className="alertCount" title="alerts count">
                        {alertsCount}
                      </span>
                    )}
                    &nbsp;
                    <i className="fa fa-angle-down" />
                  </span>{" "}
                </a>
                <div className="dropdown-menu dropdown-menu-right animated flipInY">
                  {role === "ADMIN" && (
                    <Link to={`/myprofile`} className="dropdown-item">
                      <i className="fa fa-user" /> My Profile
                    </Link>
                  )}
                  {role === "MANAGER" && (
                    <Link to={`/profile`} className="dropdown-item">
                      <i className="fa fa-user" /> My Profile
                    </Link>
                  )}
                  {role === "COLLECTOR" && (
                    <div className="dropdown-item">
                      <Link to={`/my-profile`} className="dropdown-item">
                        <i className="fa fa-user" /> My Profile
                      </Link>
                    </div>
                  )}
                  {role === "CUSTOMER" && (
                    <Link to={`/my-profile`} className="dropdown-item">
                      <i className="fa fa-user" /> My Profile
                    </Link>
                  )}
                  <a
                    dangerouslysethref="javascript:void(0);"
                    onClick={this.logout}
                    className="dropdown-item"
                  >
                    <i className="fa fa-power-off" /> Logout
                  </a>

                  {/* text*/}
                </div>
              </li>

              {/* </ul> */}

              {/* ============================================================== */}
              {/* End User Profile */}
              {/* ============================================================== */}
              {/* <li className="nav-item right-side-toggle"> <a className="nav-link  waves-effect waves-light" dangerouslysethref="javascript:void(0);"><i className="ti-settings" /></a></li> */}
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  collectors: state.collectors,
  authentication: state.authentication,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  getMissedAlerts: (collectorId) => dispatch(getMissedAlerts(collectorId)),
  // authMe : () => dispatch(authMe())
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
