import { productConstants } from "../constants/ActionTypes";

const initialState = {
  products: [],
};

const product = (state = initialState, { type, payload }) => {
  switch (type) {
    case productConstants.GET_PRODUCTS_ALL:
      return { ...state, products: payload };
    case productConstants.RESET_PRODUCTS_ALL:
      return { ...state, products: [] };
    default:
      return state;
  }
};

export default product;
