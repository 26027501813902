import { NotificationManager } from "react-notifications";
// import { alertConstants } from '../constants/ActionTypes'
import history from "../history";

export const alertActions = {
  success,
  error,
  info,
  clickableInfo,
  errorMessage,
};

function info(message) {
  return NotificationManager.info(message);
}

function errorMessage(message) {
  return NotificationManager.error(message);
}

function clickableInfo(message, el, deactivate, redirect) {
  return NotificationManager.info(message, "Alert", 15000, () => {
    // console.log("clicked the message");
    deactivate(el.id);
    history.push("/call-management", {
      _id: el.loanId,
      Loan_ID: el.loanNumber,
    });
  });
}

function success(message) {
  return NotificationManager.success(message);
}

function error(error) {
  if (error?.message) {
    return NotificationManager.error(error.message);
  } else if (error.response) {
    // console.log("error.response.data ", error.response.data);
    if (error.response.status === 404) {
      return NotificationManager.error(`${error.response.status} Not Found`);
      // Request made and server responded
      //   // console.log(error.response.data);
      //   // console.log(error.response.status);
      //   // console.log(error.response.headers);
    } else if (error.response.status === 401) {
      const { data } = error.response;
      // console.log("error.response.data ", error.response.data);
      return NotificationManager.error(
        ` ${error.response.status} ${data.message}`
      );
    } else if (error.response.status === 415) {
      const { data } = error.response;
      return NotificationManager.error(
        ` ${error.response.status} ${data.message} `
      );
    } else if (error.response.status === 400) {
      const { data } = error.response;
      return NotificationManager.error(
        ` ${error.response.status} ${data.message} `
      );
    } else if (error.response.status === 422) {
      const { data } = error.response;
      return NotificationManager.error(` ${error.response.status} ${data} `);
    } else if (error.response.status === 500) {
      const { data } = error.response;
      return NotificationManager.error(
        ` ${error.response.status} Internal server error ${data.message}`
      );
    }
  } else if (error.request) {
    // The request was made but no response was received
    // console.log(error.request);
    return NotificationManager.error(`Network error`);
  } else {
    // console.log(error);
    // Something happened in setting up the request that triggered an Error
    // // console.log('Error', error.message);
    return NotificationManager.error(`${error.message}`);
  }

  // return NotificationManager.error(message);
}
