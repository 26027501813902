import { NotificationSavingData } from "../constants/ActionTypes";

const initialState = {
  notificationSavingData: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case NotificationSavingData.SAVED_NOTIFICATION_DATA:
      return { ...state, notificationSavingData: payload };

    default:
      return state;
  }
};
