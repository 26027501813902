import { adminConstants } from "../constants/ActionTypes";

let collektoUser = JSON.parse(localStorage.getItem("collektoUser"));
const initialState = collektoUser
  ? {
      loggedIn: true,
      loading: false,
      collektoUser,
      dashboard: [],
      myprofile: {},
    }
  : {
      loggedIn: false,
      loading: false,
      dashboard: [],
    };
// console.log("authentication state ", initialState);
const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminConstants.START_SPINNER:
      return {
        ...state,
        loading: true,
      };

    case adminConstants.STOP_SPINNER:
      return {
        ...state,
        loading: false,
      };
    case adminConstants.LOGIN_SUCCESS:
      const newAuth = {
        ...state,
        loggedIn: true,
        loading: false,
        collektoUser: action.collektoUser,
      };
      return newAuth;

    case adminConstants.DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
      };
      return newAuth;
    case adminConstants.MY_PROFILE:
      return {
        ...state,
        myprofile: action.payload,
      };
    case adminConstants.LOGIN_FAILURE:
      // console.log(`action.payload `, action.payload);
      return {
        ...state,
        loggedIn: false,
        loading: true,
      };
    case adminConstants.LOGOUT:
      return {};
    default:
      return state;
  }
};

export default authenticationReducer;
