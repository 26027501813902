export const RegistrationRoles = {
  FIELD_AGENT: "fieldAgent",
  TENANT_ADMIN: "tenantAdmin",
  SUPER_MANAGER: "superManager",
  TENANT: "Tenant-Onboarding",
  TELECALLER: "teleCaller",
  MANAGER: "manager",
};

export const secureLists = [
  { label: "HOME LOAN", value: "HOME LOAN" },
  { label: "LOAN AGAINST PROPERTY", value: "LOAN AGAINST PROPERTY" },
  { label: "WORKING CAPITAL", value: "WORKING CAPITAL" },
  { label: "CAR LOAN", value: "CAR LOAN" },
  { label: "COMMERCIAL VEHICLE LOAN", value: "COMMERCIAL VEHICLE LOAN" },
  { label: "TWO WHEELER LOAN", value: "TWO WHEELER LOAN" },
  { label: "NONE", value: "NONE" },
];

export const unsecureLists = [
  { label: "SALARIED PERSONAL LOAN", value: "SALARIED PERSONAL LOAN" },
  { label: "SMALL TICKET PERSONAL LOAN", value: "SMALL TICKET PERSONAL LOAN" },
  { label: "BUSINESS LOANS", value: "BUSINESS LOANS" },
  { label: "CREDIT CARD", value: "CREDIT CARD" },
  { label: "NONE", value: "NONE" },
];

export const southIndLngList = [
  { label: "TAMIL", value: "TAMIL" },
  { label: "TELGU", value: "TELGU" },
  { label: "KANNADA", value: "KANNADA" },
  { label: "MALYALAM", value: "MALYALAM" },
  { label: "NONE", value: "NONE" },
];

export const reginalLanList = [
  { label: "HINDI", value: "HINDI" },
  { label: "ENGLISH", value: "ENGLISH" },
  { label: "MARATHI", value: "MARATHI" },
  { label: "GUJARATI", value: "GUJARATI" },
  { label: "BENGALI", value: "BENGALI" },
  { label: "PUNJABI", value: "PUNJABI" },
  { label: "ORIYA", value: "ORIYA" },
];

export const clients = [
  { label: "BANK", checked: false },
  { label: "NBFC", checked: false },
  { label: "FINTECH", checked: false },
  { label: "ARC", checked: false },
];

export const buckets = [
  [
    { value: "0-30", checked: false },
    { value: "31-60", checked: false },
    { value: "61-90", checked: false },
  ],
  [
    { value: "91-180", checked: false },
    { value: "180-360", checked: false },
    { value: "360+", checked: false },
  ],
];

export const androidVersion = [
  { version: "1.0", code_name: "Base", release_date: "2008" },
  { version: "1.1", code_name: "Base 1.1", release_date: "2009" },
  { version: "1.5", code_name: "Cupcake", release_date: "2009" },
  { version: "1.6", code_name: "Donut", release_date: "2009" },
  { version: "2.0 - 2.1", code_name: "Eclair", release_date: "2009" },
  { version: "2.2 - 2.2.3", code_name: "Froyo", release_date: "2010" },
  { version: "2.3 - 2.3.7", code_name: "Gingerbread", release_date: "2010" },
  { version: "3.0 - 3.2.6", code_name: "Honeycomb", release_date: "2011" },
  {
    version: "4.0 - 4.0.4",
    code_name: "Ice Cream Sandwich",
    release_date: "2011",
  },
  { version: "4.1 - 4.3.1", code_name: "Jelly Bean", release_date: "2012" },
  { version: "4.4 - 4.4.4", code_name: "KitKat", release_date: "2013" },
  { version: "5.0 - 5.1.1", code_name: "Lollipop", release_date: "2014" },
  { version: "6.0 - 6.0.1", code_name: "Marshmallow", release_date: "2015" },
  { version: "7.0 - 7.1.2", code_name: "Nougat", release_date: "2016" },
  { version: "8.0 - 8.1", code_name: "Oreo", release_date: "2017" },
  { version: "9", code_name: "Pie", release_date: "2018" },
  { version: "10", code_name: "Android 10", release_date: "2019" },
  { version: "11", code_name: "Android 11", release_date: "2020" },
  { version: "12", code_name: "Android 12", release_date: "2021" },
  { version: "13", code_name: "Android 13", release_date: "2022" },
  { version: "14", code_name: "Android 14", release_date: "2023" },
  { version: "15", code_name: "Android 15", release_date: "2024" },
];

export const totalExp = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];

const tenantAdminValidationField = [
  "fullName",
  "mobile",
  "email",
  "password",
  "confirmPassword",
  "totalExperience",
];

const teleCallerValidationField = [
  "fullName",
  "mobile",
  "email",
  "password",
  "confirmPassword",
  "convoxUsername",
  // "xliteExtensionNo",
  "dialerLocation",
  "draCertification",
  "collectionExperience",
  "totalExperience",
  "clients",
  "buckets",
  "securedProducts",
  "unsecuredProducts",
  "southIndianLan",
  "otherLanguages",
];

const managerValidationField = [
  "fullName",
  "mobile",
  "email",
  "password",
  "confirmPassword",
  "convoxUsername",
  // "xliteExtensionNo",
  "modelNo",
  "IMEI",
  "androidVersion",
  "collectionExperience",
  "totalExperience",
  "clients",
  "buckets",
  "securedProducts",
  "unsecuredProducts",
  "southIndianLan",
  "otherLanguages",
];

const superManagerValidationFields = [
  "fullName",
  "mobile",
  "email",
  "password",
  "confirmPassword",
  // "xliteExtensionNo",
  "collectionExperience",
  "totalExperience",
  "clients",
  "buckets",
  "securedProducts",
  "unsecuredProducts",
  "southIndianLan",
  "otherLanguages",
];

const fieldAgentValidationFields = [
  "fullName",
  "mobile",
  "gender",
  "modelNo",
  "IMEI",
  "androidVersion",
  "draCertification",
  "collectionExperience",
  "totalExperience",
  "clients",
  "buckets",
  "securedProducts",
  "unsecuredProducts",
  "southIndianLan",
  "otherLanguages",
];

export const isValidationRequired = (role, value) => {
  if (role === RegistrationRoles.TENANT_ADMIN) {
    return tenantAdminValidationField.includes(value);
  }
  if (role === RegistrationRoles.TELECALLER) {
    return teleCallerValidationField.includes(value);
  }
  if (role === RegistrationRoles.MANAGER) {
    return managerValidationField.includes(value);
  }
  if (role === RegistrationRoles.SUPER_MANAGER) {
    return superManagerValidationFields.includes(value);
  }
  if (role === RegistrationRoles.FIELD_AGENT) {
    return fieldAgentValidationFields.includes(value);
  }
  return false;
};
