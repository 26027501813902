import Axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import * as XLSX from "xlsx";
import { API_URL } from "../../constants/ActionTypes";
import moment from "moment";
import "./ExportCSV.scss";

const defaultOptions = {
  headers: { Authorization: "Bearer " + localStorage.getItem("token") },
};

export class ExportCSV extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      gte: "",
      lte: "",
      model: "",
    };
  }
  onChangeHandle = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  exportCsv = (e) => {
    // console.log(`// console.log `);
    if (
      this.validator.fieldValid("From Date") &&
      this.validator.fieldValid("End Date")
    ) {
      Axios.get(
        `${API_URL}/activity/get-export?model=${this.state.model}&gte=${this.state.gte}&lte=${this.state.lte}`,
        defaultOptions
      ).then((res) => {
        // console.log(`res.data `, res.data);
        if (res.data.length > 0) {
          // let result = res.data.map(({ loanId, ...object }) => ({...loanId,  ...object }));
          let result = res.data;
          let keys = Object.keys(result[0]);
          result = result.map((r) => {
            // r.Loan_ID = "\t "+r.Loan_ID+" \t"
            r.date = r.date ? moment(r.date).format("DD-MM-YYYY") : "";
            r.createdAt = moment(r.createdAt).format("DD-MM-YYYY hh:mm A");
            r.updatedAt = moment(r.updatedAt).format("DD-MM-YYYY hh:mm A");
            return r;
          });
          // moment().format('DD-MM-YYYY hh:mm A', new Date())
          // console.log(`result `, result);
          const fileName = `${this.state.model}_${this.state.gte}_${this.state.lte}.xlsx`;
          // const wb = XLSX.read(result, {locale:"en-US"});
          // const wb = XLSX.utils.read(result, {cellText:false, cellDates:true});
          const ws = XLSX.utils.json_to_sheet(result, {
            cellText: true,
            cellDates: true,
            blankrows: true,
            skipHidden: true,
          });
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws);
          XLSX.writeFile(wb, fileName);
        } else {
          alert(`No data found`);
        }
      });
    } else {
      // console.log(`no valid`);

      // this.setState({errorMessage : 'Please select the from and two date'})
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleSubmitConfirm = () => {
    // console.log(`this.state `, this.state);
    if (
      this.validator.fieldValid("From Date") &&
      this.validator.fieldValid("End Date")
    ) {
      const { activeLoans } = this.props.callmanagement;
      const { disposition_class } = this.state;
      // console.log(`disposition_class `, disposition_class);
      // console.log(`activeLoans `, activeLoans);
      let lte = moment(this.state.lte).format("YYYY-DD-MM");
      let gte = moment(this.state.gte).format("YYYY-DD-MM");
      // var al
      if (disposition_class == "DEFAULT") {
        const al = activeLoans.filter(
          (c) =>
            moment(c.disCreatedAt).format("YYYY-DD-MM") <= lte &&
            moment(c.disCreatedAt).format("YYYY-DD-MM") >= gte
        );
        // console.log(`al `, al);
        this.setState({ activeLoans: al });
      } else {
        const al = activeLoans.filter(
          (c) =>
            c.disposition_class === disposition_class &&
            moment(c.disCreatedAt).format("YYYY-DD-MM") <= lte &&
            moment(c.disCreatedAt).format("YYYY-DD-MM") >= gte
        );
        // console.log(`al `, al);
        this.setState({ activeLoans: al });
      }

      // this.props.getSearchActiveLoan(this.collectorId, this.createdBy, this.state.lte, this.state.gte, this.state.disposition_class)
    } else {
      // console.log(`no valid`);

      // this.setState({errorMessage : 'Please select the from and two date'})
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    return (
      <div className="page-wrapper">
        {/* ============================================================== */}
        {/* Container fluid  */}
        {/* ============================================================== */}
        <div className="container-fluid">
          {/* ============================================================== */}
          {/* Bread crumb and right sidebar toggle */}
          {/* ============================================================== */}
          <div className="row page-titles">
            <div className="col-md-5 align-self-center">
              <h4 className="text-themecolor exportCSV">Export CSV</h4>
            </div>
            <div className="col-md-7 align-self-center text-right">
              <div className="d-flex justify-content-end align-items-center">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">Home</li>
                  <li className="breadcrumb-item active exportCSV">
                    Export CSV
                  </li>
                </ol>
                {/* <button type="button" className="btn btn-sm btn-info d-none d-lg-block m-l-15"><i className="fa fa-plus-circle" /> Create New</button> */}
              </div>
            </div>
          </div>
          {/* ============================================================== */}
          {/* End Bread crumb and right sidebar toggle */}
          {/* ============================================================== */}
          {/* ============================================================== */}
          {/* Start Page Content */}
          {/* ============================================================== */}
          <div className="row">
            <div className="col-md-12 data_visulation_col">
              <div className="card">
                <div className="card-body">
                  {/* Export CSV */}
                  <div className="row">
                    <div className="col-md-2 zi-15">
                      <label className="call_mage_label1">Select Model</label>
                      <select
                        className="form-control form-control-sm"
                        defaultValue={"DEFAULT"}
                        name="model"
                        onChange={this.onChangeHandle}
                      >
                        <option value={"DEFAULT"}>Select Model</option>
                        <option value={"loanModel"}>Loan Model</option>
                        <option value={"callDispositionModel"}>
                          Call Disposition Model
                        </option>
                        <option value={"dataInsightModel"}>
                          Data Insight Model
                        </option>
                        <option value={"targetModel"}>Target Model</option>
                      </select>
                      <span className="text-danger small">
                        {this.validator.message(
                          "model",
                          this.state.model,
                          "required",
                          { className: "text-danger" }
                        )}{" "}
                      </span>
                    </div>

                    <div className="col-md-2 zi-15">
                      <label className="call_mage_label1">From Date</label>
                      <div className="">
                        <input
                          type="date"
                          onChange={this.onChangeHandle}
                          className="form-control form-control-sm"
                          id="inputEmail3"
                          name="gte"
                          placeholder="From Date"
                          // min={moment().startOf('month').format("YYYY-MM-DD")} max={moment().format("YYYY-MM-DD")}
                        />
                      </div>
                      <span className="text-danger small">
                        {this.validator.message(
                          "From Date",
                          this.state.gte,
                          "required"
                        )}
                      </span>
                    </div>
                    <div className="col-md-2  zi-15">
                      <label className="call_mage_label1">End Date</label>
                      <div>
                        <input
                          type="date"
                          onChange={this.onChangeHandle}
                          className="form-control form-control-sm"
                          id="inputEmail3"
                          name="lte"
                          placeholder="End Date"
                          //  min={moment().startOf('month').format("YYYY-MM-DD")}  max={moment().format("YYYY-MM-DD")}
                        />
                      </div>
                      <span className="text-danger small">
                        {this.validator.message(
                          "End Date",
                          this.state.lte,
                          "required"
                        )}
                      </span>
                    </div>
                    <div className="col-md-2 zi-15">
                      <button
                        type="button"
                        className="btn aqua-gradient btn-default btn-sm btn-block"
                        onClick={this.exportCsv}
                      >
                        {" "}
                        <i className="fas fa-cloud-download-alt exportCSV"></i>{" "}
                        Export CSV{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* ============================================================== */}
          {/* End PAge Content */}
          {/* ============================================================== */}
        </div>
        {/* ============================================================== */}
        {/* End Container fluid  */}
        {/* ============================================================== */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ExportCSV);
