import React, { createContext, useState, useContext } from "react";

const DialogContext = createContext();

export const DialogProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(true);

  const openDialog = () => {
    setIsOpen(true);
    setIsMinimized(false);
  };

  const toggleMinimize = () => {
    setIsOpen(false);
    setIsMinimized(true);
  };

  return (
    <DialogContext.Provider
      value={{ isOpen, isMinimized, openDialog, toggleMinimize }}
    >
      {children}
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  return useContext(DialogContext);
};
