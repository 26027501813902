export const agentMonitor = {
  getAgentMonitorDataUrl: "/profile/dialer/agentStatus",
  getAgentMonitorStatusUrl: "/dialer/agent/agentStatus",
};

const commonSubUrl = "/api/v2/profile";

export const supressionList = {
  uploadSupressionList: "/api/v2.1/suppression/loans/data-import",
};

export const TenantOnboarding = {
  createTenantUrl: `${commonSubUrl}/tenants`,
  getTenantUrl: `${commonSubUrl}/tenants`,
  active_inactive_url: `${commonSubUrl}/tenants/active`,
  updateTenantInfoUrl: `${commonSubUrl}/tenants`,
  getTenantByIdUrl: `/api/v2/profile/tenants/id`,
  getReportsUrl: `/api/v2/reports`,
  validateTenantName: "/api/v2/profile/validate/tenantName",
};

export const RegistrationUrls = {
  getManagerByTenantIdUrl: `${commonSubUrl}/managers`,
  createSuperManagerUrl: `${commonSubUrl}/superManager`,
  createFieldAgentUrl: `${commonSubUrl}/fieldAgents`,
  createManagerUrl: `${commonSubUrl}/manager`,
  createTeleCallerUrl: `${commonSubUrl}/teleCaller`,
  createAdminUrl: `${commonSubUrl}/tenantAdmin`,
  getRegistrationDetail: `/api/v2/profile/search`,
  active_inactive_url: `/api/v2/profiles/active`,
  getProfileByIdUrl: "/api/v2/profiles/id",
};

export const processUpload = {
  UPLOAD: "/api/v2/dialer/processes/list/dataImport",
};

export const resetPasswordUrl = {
  VALIDATE: "/api/v2/profiles/resetPassword/validate",
  RESET: "/api/v2/profile/teleCaller/reset",
};

export const PortfolioUrls = {
  getProductUrl: "/api/v2/products",
  getReportByTenantId: "/api/v2/profile/tenants/id/reports",
  createPortfolioUrl: "/api/v2/portfolios",
  getPortfolioById: "/api/v2/portfolios/",
  active_inactive_url: "/api/v2/portfolios/active",
  updatePortfolioUrl: "/api/v2/portfolios/",
  updatePortfolioScriptUrl: "/api/v2/portfolios/scripts",
  updatePortfolioForclosureUrl: "/api/v2/portfolios/settlementAndForeclosure",
  updateIncentiveUrl: "/api/v2/portfolios/incentives",
  getReportInSettlement: "/api/v2/portfolios/settlementAndForeclosure",
  uploadReportInSettlement:
    "/api/v2/portfolios/settlementAndForeclosure/data-import",
  settlementForclosureLetterUrl:
    "/api/v2/portfolios/settlementAndForeclosure/letters",
  uploadFileOnS3Url: "/upload/singleFile",
  editSrAndFrLetterUrl: "/api/v2/portfolios/settlementAndForeclosure/letters",
  SRandFrListingUrl: "/api/v2/portfolios/settlementAndForeclosure/letters/list",
  GET_SR_FR_BY_LETTERTYPE:
    "/api/v2/portfolios/settlementAndForeclosure/letters",
  deleteLetterUrl: "/api/v2/portfolios/settlementAndForeclosure/letters/active",
  validatePortfolioUrl: "/api/v2/portfolios/validate",
};
