import React, { Component } from "react";
import history from "../../history";

export class Logout extends Component {
  constructor() {
    // console.log("kdnvdkvn");
    localStorage.clear();
    /* The line `// this.logout.bind(this);` is commented out, so it is not doing anything in the code. */
    // this.logout.bind(this);
    setTimeout(() => {
      history.push(`/login`);
      window.location.reload();
    }, 0);
    // eslint-disable-next-line no-this-before-super
    // this.logout();
  }
  render() {
    return <div>Hello guys chai pilo</div>;
  }
}

export default Logout;
